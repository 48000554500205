import { Modal, Button, Form, InputGroup, Row, Col } from 'react-bootstrap';
import { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import {Context} from '../context/AppContext';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const AddToCartModal = ({ item, modal, setModal }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [disabledAddToCart, setDisabledAddToCart] = useState(false);
  const [alertMessages, setAlertMessages] = useState({});
  const [special_instructions, setSpecialInstructions] = useState('');
  const [quantity, setQuantity] = useState(1);
  const { language, setChangedCart, changedCart, restaurantId } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  
  console.log(item)

  useEffect(() => {
    if (!modal) {
      // Reset state when modal is closed
      setSelectedOptions([]);
      setDisabledAddToCart(false);
      setAlertMessages({});
      setSpecialInstructions('');
      setQuantity(1);
    }
  }, [modal]);

  useEffect(() => {
    const validateOptions = () => {
      let allOptionsValid = true;
      let alerts = {};
  
      item?.Restaurant_Item_Attributes.forEach((attribute) => {
        const requiredOptions = attribute?.Item_Attribute?.requ || 0;
        const maxOptions = attribute?.Item_Attribute?.max_ || 0;
        const selectedOptionsCount = selectedOptions.filter(opt => opt.attributeId === attribute.id).length;
  
        // Check for required options
        if (requiredOptions > selectedOptionsCount && requiredOptions !== 0) {
          allOptionsValid = false;
          alerts[attribute.id] = `*Must Select ${requiredOptions} options and you selected ${selectedOptionsCount}`;
        }
  
        // Check for maximum options
        if (maxOptions < selectedOptionsCount && maxOptions !== 0) {
          allOptionsValid = false;
          alerts[attribute.id] = `*Maximum of ${maxOptions} options can be selected and you selected ${selectedOptionsCount}`;
        }
  
        // Clear the alert if no issue
        if (!alerts[attribute.id]) {
          alerts[attribute.id] = '';
        }
      });
  
      setAlertMessages(alerts);
      setDisabledAddToCart(!allOptionsValid);
    };
  
    validateOptions();
  }, [selectedOptions, item]);

  const handleIncrease = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  const handleChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    if (!isNaN(newQuantity)) {
      setQuantity(newQuantity);
    }
  };

  const handleOptionChange = (optionId, priceModifier, attributeId) => {
    const existingOption = selectedOptions.find((opt) => opt.id === optionId);

    if (existingOption) {
      // If the option is already selected, remove it
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((opt) => opt.id !== optionId)
      );
    } else {
      // If the option is not selected, add it
      setSelectedOptions((prevOptions) => [
        ...prevOptions,
        { id: optionId, priceModifier, attributeId },
      ]);
    }
  };

  const calculateTotalPrice = () => {
    let totalPrice = +item?.price || 0;
    selectedOptions.forEach((option) => {
      totalPrice += +option.priceModifier || 0;
    });

    totalPrice *= +quantity;
    return totalPrice.toFixed(2);
  };

  const handleAddItem = () => {
    try {
      setIsLoading(true);
  
      const cart = JSON.parse(localStorage.getItem(`cart${restaurantId}`)) || [];
  
      const itemToAdd = {
        Item: item,
        quantity: quantity,
        special_instructions: special_instructions,
        options: selectedOptions,
        price: calculateTotalPrice(),
      };
  
      // Check if an identical item already exists in the cart
      const existingItemIndex = cart.findIndex(
        cartItem =>
          cartItem.Item.id === itemToAdd.Item.id &&
          cartItem.special_instructions === itemToAdd.special_instructions &&
          JSON.stringify(cartItem.options) === JSON.stringify(itemToAdd.options)
      );
  
      if (existingItemIndex !== -1) {
        // If the item already exists, increase its quantity
        cart[existingItemIndex].quantity += quantity;
      } else {
        // Otherwise, add the new item to the cart
        cart.push(itemToAdd);
      }
  
      localStorage.setItem(`cart${restaurantId}`, JSON.stringify(cart));
  
      setIsLoading(false);
      setSpecialInstructions('');
      setQuantity(1);
      setSelectedOptions([]);
      setModal(false);
      setChangedCart(!changedCart);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };


  

  return (
    <Modal show={modal} onHide={() => setModal(false)} centered scrollable size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{language === 'en' ? item?.name : item?.chinese_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center">
          {item?.image_url && (
            <img
              src={item?.image_url}
              alt={language === 'en' ? item?.name : item?.chinese_name}
              className="img-fluid mb-3"
              style={{ width: '100%', maxHeight: '220px', objectFit: 'cover' }}
            />
          )}
          {item?.description && item?.description !== 'null' && (
            <p>{item?.description}</p>
          )}
          {item?.Restaurant_Item_Attributes && item.Restaurant_Item_Attributes.length > 0 && (
            <div className="w-100">
              <h6>Options:</h6>
              <Form>
              {item?.Restaurant_Item_Attributes
    ?.sort((a, b) => {
      // Sorting by name numerically or alphabetically
      return a?.Item_Attribute?.name?.localeCompare(b?.Item_Attribute?.name, undefined, { numeric: true });
    })?.map((attribute) => (
                  <Form.Group key={attribute.id} className="mb-3">
                    <Form.Label className="mb-2">{attribute?.Item_Attribute?.name} <br />
                      <small className="text-danger">{alertMessages[attribute.id]}</small>
                    </Form.Label>

                    <div className="d-flex flex-wrap">
                      {attribute?.Item_Attribute?.Item_Attribute_Options?.map((option) => (
                        <div className='checkbox-wrapper-47 m-0' key={option.id}>
                          <input
                            type="checkbox"
                            id={option.name}
                            name={option.name}
                            value={option.id}
                            onChange={() => handleOptionChange(option.id, option.price_modifier, attribute.id)}
                          />
                          <label htmlFor={option.name}>{option.name} {option?.price_modifier > 0 ? `+ $${option.price_modifier}` : null}</label>
                        </div>
                      ))}
                    </div>
                  </Form.Group>
                ))}
              </Form>
            </div>
          )}
          <Form.Group className="w-100 mb-3">
            <Form.Label>Special Instructions:</Form.Label>
            <Form.Control value={special_instructions} onChange={(e) => setSpecialInstructions(e.target.value)} type="text" placeholder="Add special instructions..." />
          </Form.Group>
      
        </div>
      </Modal.Body>

      <div className="row" style={{ backgroundColor: '#ffae00' }}>
      <div className="col-md-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', borderRadius: '5px', textAlign: 'center', backgroundColor: '#F0FFF0' }}>
        <InputGroup style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <InputGroup.Text onClick={handleDecrease} style={{ cursor: 'pointer', backgroundColor: 'white' }}>
            <FontAwesomeIcon icon={faMinus} />
          </InputGroup.Text>
          <span style={{ fontSize: '18px', margin: '0 10px' }}>{quantity}</span>
          <InputGroup.Text onClick={handleIncrease} style={{ cursor: 'pointer', backgroundColor: 'white' }}>
            <FontAwesomeIcon icon={faPlus} />
          </InputGroup.Text>
        </InputGroup>
      </div>
      <div
  className="col-md-8"
  style={{
    backgroundColor: '  #a6e01e	',
    color: 'white',
    padding: '10px',
    textAlign: 'center',
    cursor: disabledAddToCart ? 'not-allowed' : 'pointer', // Change cursor when disabled
    opacity: disabledAddToCart ? 0.6 : 1, // Add opacity for visual feedback when disabled
  }}
  onClick={!disabledAddToCart ? handleAddItem : undefined} // Prevent onClick when disabled
>
  {isLoading ? (
    <span className="spinner-border"></span>
  ) : (
    `Add to order $${calculateTotalPrice()}`
  )}
</div>
    </div>


    </Modal>
  );
};

export default AddToCartModal;
