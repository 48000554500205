import { useNavigate } from "react-router-dom"
import { FaFacebook, FaInstagram } from "react-icons/fa"
import petallogo from '../assets/images/petallogo.jpg'
import { useContext, useState } from "react"
import { Modal } from "react-bootstrap"
import { Context } from "../context/AppContext"

const MainBanner2 = () => {
  const navigate = useNavigate()
  const [modal, showModal] = useState(false)
  const { setRestaurantId } = useContext(Context)

  const handleOpenModal = () => {
    showModal(true)
  }

  const handleToOrder = (id) => {
    setRestaurantId(id)
    navigate("/online-order")
  }


  return (
    <div
      className="main-bnr-three overflow-hidden "
      style={{
        backgroundImage: `url(${petallogo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "80vh",
        position: "relative"
      }}
    >
      <div className="top-banner-text text-center mt-5">
        <span className="petals-text text-white">
          PETALS OF A PEONY
        </span>
      </div>
      <div className="center-content d-flex flex-column justify-content-center align-items-center text-center" >
        <h2 className=" text-white" style={{ whiteSpace: 'nowrap', margin: '10px', fontSize: '40px' }}>
          A TASTE OF CHINA
        </h2>
        <div className="banner-btn d-flex justify-content-center align-items-center">
          <div
            onClick={handleOpenModal}
            className="btn btn-primary btn-md shadow-primary m-r30 btn-hover-1"
          >
            <span>Order Online</span>
          </div>
          <div
            onClick={() => navigate("/menu")}
            className="btn btn-secondary btn-md shadow-primary btn-hover-1"
          >
            <span>View Menu</span>
          </div>
        </div>
      </div>
      <div className="social-icons d-flex justify-content-center align-items-center">
        <a href="https://www.instagram.com/petalsofapeony/" target="_blank" rel="noopener noreferrer" className="mx-2">
          <FaInstagram size={55} color="white" />
        </a>
        <a href="https://www.facebook.com/PetalsofaPeony/" target="_blank" rel="noopener noreferrer" className="mx-2">
          <FaFacebook size={55} color="white" />
        </a>
      </div>



      <Modal show={modal} onHide={() => showModal(false)} centered >
        <Modal.Header closeButton>
          <Modal.Title>Choose a location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row ">
            <div className="col-md-6 text-center">
              <p className="text-center">
                Cordova Loaction
                <br />
                1250 N Germantown Pkwy Cordova, TN 38016
                <br />
                (901)-707-8886
              </p>
              <div
                onClick={() => handleToOrder(7)}
                className="btn btn-primary btn-md shadow-primary m-r30 btn-hover-1"
              >
                <span>Order Here</span>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <p className="text-center">
                Overton Square Location
                <br />
                2110 Madison Ave Memphis, TN 38104
                <br />
                (901)-207-6680
              </p>
              <div
                onClick={() => handleToOrder(11)}
                className="btn btn-primary btn-md shadow-primary btn-hover-1"
              >
                <span>Order Here</span>
              </div>
            </div>

          </div>

        </Modal.Body>
      </Modal>
    </div>
  )
}

export default MainBanner2
