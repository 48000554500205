import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";


export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }



    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Your payment was successful!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);


    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/order-confirmed`, // Update the return_url

        // return_url: `http://localhost:3000/order-confirmed/${+tip}/${encodeURIComponent(name)}/${encodeURIComponent(phone)}/${encodeURIComponent(coupon)}/${encodeURIComponent(address)}`
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  }

  return (
    <form
      id="payment-form"
      style={{
        margin: "0 auto",
        padding: "20px",
        border: "1px solid #e6e6e6",
        borderRadius: "4px",
      }}
      onSubmit={handleSubmit}
    >
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        style={{
          borderRadius: "4px",
          border: "0",
          padding: "12px 16px",
          fontSize: "16px",
          fontWeight: "600",
          cursor: "pointer",
          display: "block",
          transition: "all 0.2s ease",
          boxShadow: "0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)",
          width: "100%",
          marginTop: "12px",
        }}
        className="btn btn-gray btn-hover-2"
      >
        <span id="button-text">
          {isLoading ? (
            <p>Processing Payment... <br /> DO NOT LEAVE THIS PAGE!</p>
          ) : (
            "Place Order"
          )}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && (
        <div
          id="payment-message"
          style={{
            color: "#3498db", // Change the color as needed
            fontWeight: "bold",
            paddingTop: "12px",
            textAlign: "center",
            fontSize: '18px'
          }}
        >
          {message}
        </div>
      )}
    </form>
  );
}
