import "swiper/css/effect-fade";
import "swiper/css/free-mode";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import "swiper/css";
import "react-modal-video/css/modal-video.css";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "./assets/css/common.css";
import "rsuite/dist/rsuite.min.css";
import "./assets/other/switcher/switcher.css";
import "./assets/css/style.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import ScrollTop from "./constent/ScrollTop";
import AboutUs from "./pages/AboutUs";
import Faq from "./pages/Faq";

import ComingSoon from "./pages/ComingSoon";
// import Account from "./pages/Account";

import ShopCheckout from "./pages/ShopCheckout";
import ContactUs from "./pages/ContactUs";
import OnlineOrder from "./pages/OnlineOrder";
import { useDispatch, useSelector } from "react-redux";
import { getRestaurantThunk } from "./store/restaurants";
import Home2 from "./pages/Home2";
import { useContext, useEffect, useState } from "react";
import Order from "./pages/Order";
import { checkIfLunchTime } from "./Utilts/helper";
import ImageMenuComponent from "./pages/ImageMenu";
// import CheckWaitList from "./pages/CheckWaitList";
// import WaitlistPage from "./pages/WaitList";
import { Context } from "./context/AppContext";
import Loader from "./elements/Loader";
import CateringForm from "./pages/Catering";
import Error404 from "./pages/Error404";
import Header from "./components/Header";


function App() {
  const dispatch = useDispatch()
  const restaurant = useSelector((state) => state.restaurant.restaurant);
  const { restaurantId} = useContext(Context)
  const [loading, setLoading] = useState(false)

  useEffect(() => {

    const fetchRestaurant = async () => {
      try { 
        setLoading(true)
        await dispatch(getRestaurantThunk(restaurantId));
      }catch (error) {
        console.log(error)
        setLoading(false)
      }finally { 
        setLoading(false)
      }
      
    }

    if(restaurantId === null) {
      setLoading(false)
    }
    else {
      fetchRestaurant()
    }
    
  }, [dispatch,restaurantId]); 

  const filteredRestaurant = restaurant && !checkIfLunchTime()
    ? {
        ...restaurant,
        Categories: restaurant.Categories.filter(category => category.id !== 87 && category.id !== 150)
      }
    : restaurant;

  return (
    <>
      {loading && <Loader />}
      <div className="page-wraper">
        <Router>
          {/* <SignIn /> */}
          <ScrollTop />
        
          <Routes>
              <Route exact path="/" element={<Home2 restaurant={filteredRestaurant} />} />
              <Route path="/online-order" element={<OnlineOrder restaurant={filteredRestaurant}/>} />
              <Route path="/menu" element={<ImageMenuComponent restaurant={filteredRestaurant}/>} />

              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/faq" element={<Faq />} />

              <Route path="/checkout" element={<ShopCheckout fee={restaurant?.Restaurant_Fees[0]} />} />

              <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/order-confirmed" element={<ComingSoon />} />
            <Route path='/order/:id/:restaurant_id' element={<Order />} />
            {/* <Route path="/account" element={<Account />} /> */}
            {/* <Route path='/waitlist/:id' element={<CheckWaitList restaurant={restaurant}/>} /> */}
            {/* <Route path='/join-waitlist' element={<WaitlistPage restaurant={restaurant}/>} /> */}
            <Route path="/catering"  element={<CateringForm />}/>


            {/* 404 page */}
            <Route path="*" element={<Error404 />} />
            </Routes>
            
          {/* <Switcher />// */}
        </Router>
      </div>
    </>
  );
}

export default App;
